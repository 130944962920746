import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAlert} from "../typescript/redux/actions";
import {
    AttachMoney,
    Cancel,
    CheckCircle,
    Delete as DeleteIcon,
    Error,
    List,
    MoneyOff,
    Print
} from "@mui/icons-material";
import {Datatable} from "../index";
import {DocumentService} from "./document.service";
import {
    Chip,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import DocumentListForm from "./document-list-form";
import {useNavigate, useParams} from "react-router-dom";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle} from "../typescript/redux/actions";
import {NumberUtil} from "../typescript/util/number-util";
import {DateUtil} from "../typescript/util/date-util";
import {FileUtil} from "../typescript/util/file-util";

const classes = {
    chips: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    rowDanger: {
        backgroundColor: '#f2dedeb8'
    },
    rowSuccess: {
        backgroundColor: '#dff0d8c9'
    },
    rowWarning: {
        backgroundColor: '#fcf8e3'
    },
};

export function DocumentList() {

    let navigate = useNavigate();

    const {person} = useParams();
    const {costCenter} = useParams();
    const {year} = useParams();
    const {month} = useParams();
    const dispatch = useDispatch();
    const isAdmin = AuthService.userInRole("ADMIN");
    const isMaster = AuthService.userInRole("MASTER");
    const [toReceive, setToReceive] = useState(person || costCenter ? null : true);
    const [byPayment, setByPayment] = useState(person || costCenter ? null : false);
    const [yearSelected, setYearSelected] = useState(person ? null : costCenter ? year : DateUtil.currentYear());
    const [monthSelected, setMonthSelected] = useState(person ? null : costCenter ? month : DateUtil.currentMonth());
    const [items, setItems] = useState(null);

    const findAll = useCallback(async () => {
        const personToRequest = person ? Number(person) : null;
        const costCenterToRequest = costCenter ? Number(costCenter) : null;
        const result = await DocumentService.findAll(toReceive, byPayment, yearSelected, monthSelected, personToRequest, costCenterToRequest);
        setItems(result);
    }, [person, toReceive, byPayment, yearSelected, monthSelected, costCenter]);

    useEffect(() => {
        if (person) {
            dispatch(changePageTitle("Documentos da pessoa " + person));
        } else if (costCenter) {
            dispatch(changePageTitle("Documentos do centro de custo " + costCenter + " - " + year + "/" + month));
        } else {
            dispatch(changePageTitle("Documentos"));
        }
        findAll().then();
    }, [dispatch, findAll, person, costCenter, year, month]);

    const editItem = (item) => {
        navigate('/document/' + item.id);
    }

    const insertItem = () => {
        navigate('/document');
    }

    const parcels = () => {
        navigate('/document/parcels');
    }

    const logsUpdates = () => {
        navigate('/documents/logs/updates');
    }

    const logsDeletes = () => {
        navigate('/documents/logs/deletes');
    }

    const logsBaixas = () => {
        navigate('/documents/logs/baixas');
    }

    const deleteItem = (item) => {
        DocumentService.delete(item.id).then(() => {
            findAll().then();
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao excluir documento - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const backToPersons = () => {
        navigate('/persons');
    }

    const backToMonthlyBudgets = () => {
        navigate('/monthlybudget');
    }

    let headerOperations;
    if (person) {
        headerOperations = [
            {color: () => "primary", onClick: backToPersons, label: () => 'Voltar para pessoas'},
        ]
    } else if (costCenter) {
        headerOperations = [
            {color: () => "primary", onClick: backToMonthlyBudgets, label: () => 'Voltar para orçamentos mensais'},
        ]
    } else {
        if (isAdmin) {
            headerOperations = [
                {color: () => "primary", onClick: insertItem, label: () => 'Inserir'},
                {color: () => "primary", onClick: parcels, label: () => 'Gerar parcelas a pagar'},
                {color: () => "primary", onClick: logsUpdates, label: () => 'Logs de alterações'},
                {color: () => "primary", onClick: logsDeletes, label: () => 'Logs de exclusões'},
                {color: () => "primary", onClick: logsBaixas, label: () => 'Logs de baixas'}
            ]
        } else if (isMaster) {
            headerOperations = [
                {color: () => "primary", onClick: insertItem, label: () => 'Inserir'},
                {color: () => "primary", onClick: parcels, label: () => 'Gerar parcelas a pagar'}
            ]
        } else {
            headerOperations = [
                {color: () => "primary", onClick: insertItem, label: () => 'Inserir'}
            ]
        }
    }

    const pagar = async (item) => {
        if (item.dataPagamento) {
            DocumentService.cancelPayment(item.id).then(() => {
                dispatch(setAlert({show: true, message: 'Pagamento cancelado com sucesso', severity: 'success'}));
                findAll();
            }).catch((error) => {
                let message = 'Erro ao cancelar pagamento';
                if (error.response.data.message) {
                    message += ' - ' + error.response.data.message;
                }
                dispatch(setAlert({show: true, message: message, severity: 'error'}));
            })
        } else {
            navigate('/document/' + item.id + '/pay');
        }
    }

    const labelPagar = (item) => {
        return item.dataPagamento ? 'Cancelar pagamento' : 'Pagar';
    }

    const vendas = async (item) => {
        navigate('/document/' + item.id + '/saleitem');
    }

    const recibo = async (item) => {
        DocumentService.recibo58x135(item.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        });
    }

    const showRecibo = (item) => {
        return !!item.dataPagamento;
    }

    const iconPagar = (item) => {
        return item.dataPagamento ? <MoneyOff/> : <AttachMoney/>;
    }

    const rowOperations = [
        {color: () => "primary", onClick: deleteItem, label: () => 'Excluir', icon: () => <DeleteIcon/>},
        {color: () => "primary", onClick: pagar, label: labelPagar, icon: iconPagar},
        {color: () => "primary", onClick: vendas, label: () => 'Vendas', icon: () => <List/>},
        {
            color: () => "primary",
            onClick: recibo,
            label: () => 'Recibo',
            icon: () => <Print/>,
            show: showRecibo
        },
    ]

    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>Vencimento</TableCell>
                    <TableCell align={"right"}>1ª Semana</TableCell>
                    <TableCell align={"right"}>2ª Semana</TableCell>
                    <TableCell align={"right"}>3ª Semana</TableCell>
                    <TableCell align={"right"}>4ª Semana</TableCell>
                    <TableCell align={"right"}>5ª Semana</TableCell>
                    <TableCell align={"right"}>Mês</TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const renderTableBody = () => {
        return (
            <TableBody>
                <TableRow>
                    <TableCell>Receita</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.semana1)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.semana2)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.semana3)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.semana4)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.semana5)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.receita.total)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Despesa</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.semana1)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.semana2)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.semana3)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.semana4)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.semana5)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.despesa.total)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Saldo</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.semana1)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.semana2)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.semana3)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.semana4)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.semana5)}</TableCell>
                    <TableCell
                        align={"right"}>{NumberUtil.currencyFormat(items.resumoMes.saldo.total)}</TableCell>
                </TableRow>
            </TableBody>
        )
    }

    const renderResume = () => {
        return (
            <TableContainer component={Paper}>
                <Table size={"small"}>
                    {renderTableHeader()}
                    {renderTableBody()}
                </Table>
            </TableContainer>
        )
    }

    const rowClass = (index) => {
        const documento = items.documentos[index];
        if (documento.dataPagamento === null) {
            if (documento.valorFinal !== documento.saldo) {
                return classes.rowWarning;
            } else if (new Date(documento.dataVencimento + ' 00:00:00') < DateUtil.currentDateAtStartOfDay()) {
                return classes.rowDanger;
            } else {
                return classes.rowSuccess;
            }
        }
    }

    const Div = styled('div')(classes.chips);

    const renderHeader = () => {
        return !person && !costCenter && renderHeaderForm();
    }

    const renderHeaderForm = () => {
        if (isAdmin) {
            return (
                <Grid container spacing={1}>
                    <Grid item md xs={12}>
                        <DocumentListForm year={yearSelected} setYear={setYearSelected} month={monthSelected} setMonth={setMonthSelected}
                                          byPayment={byPayment} setByPayment={setByPayment} toReceive={toReceive}
                                          setToReceive={setToReceive}/>
                    </Grid>
                    <Grid item md xs={12}>
                        {renderResume()}
                        <Div>
                            <Chip color={"primary"} icon={<Cancel/>}
                                  label={'Valor total inadimplente: ' + NumberUtil.currencyFormat(items.totalInadimplente)}
                                  sx={{backgroundColor: '#d9534f', margin: '10px'}}/>
                            <Chip color={"primary"} icon={<Error/>}
                                  label={'Valor total a vencer: ' + NumberUtil.currencyFormat(items.totalAVencer)}
                                  sx={{backgroundColor: '#5cb85c', margin: '10px'}}/>
                            <Chip color={"primary"} icon={<CheckCircle/>}
                                  label={'Valor total pago: ' + NumberUtil.currencyFormat(items.totalPago)}
                                  sx={{backgroundColor: '#337ab7', margin: '10px'}}/>
                        </Div>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <DocumentListForm year={yearSelected} setYear={setYearSelected} month={monthSelected} setMonth={setMonthSelected} byPayment={byPayment}
                                  setByPayment={setByPayment} toReceive={toReceive} setToReceive={setToReceive}/>
            )
        }
    }

    return items && (
        <React.Fragment>
            {renderHeader()}
            <Datatable rowClass={rowClass}
                       dataColumnNames={['ID Venda (OS)', 'Nome', 'Telefone', 'Valor', 'Saldo', 'Vencimento', 'Pagamento', 'Parcela']}
                       dataColumns={['os', 'pessoa.nome', 'pessoa.fone1', 'valorFinal', 'saldo', 'dataVencimento', 'dataPagamento', 'parcela']}
                       dataTypes={['number', 'text', 'text', 'currency', 'currency', 'date', 'date', 'text']}
                       findAll={findAll} data={items.documentos} totalData={items.documentos.length} editData={editItem}
                       headerOperations={headerOperations} rowOperations={rowOperations}/>
        </React.Fragment>
    );
}