import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {MonthlyBudgetService} from "./monthly-budget.service";
import {
    Datatable,
    ForeignKey
} from "../index";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import SelectOption from "../typescript/select-option/select-option";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";
import {useNavigate} from "react-router-dom";
import { Payment} from "@mui/icons-material";

const MonthlyBudget = () => {
    let navigate = useNavigate();
    const [items, setItems] = useState(null);
    const dispatch = useDispatch();
    const [ano, setAno] = useState(DateUtil.currentYear());
    const [mes, setMes] = useState(DateUtil.currentMonth());
    const [centrodecusto, setCentrodecusto] = useState(null);
    const [addBudget, setAddBudget] = useState(false);
    const [valor, setValor] = useState(null);

    const findAll = useCallback(async () => {
        const result = await MonthlyBudgetService.findByAnoAndMes(ano, mes);
        setItems(result);
    }, [ano, mes]);
    const anoOptions = DateUtil.startYears();
    const mesOptions = DateUtil.startMonths();

    useEffect(() => {
        dispatch(changePageTitle("Orçamento mensal"));
        findAll().then();
    }, [dispatch, findAll]);

    const onChangeCentroDeCusto = async (id) => {
        setCentrodecusto({
            id: id
        });
        if (id) {
            const centrodecusto = await CentroDeCustoService.findOne(id);
            onSelectCentroDeCusto(centrodecusto);
        }
    }

    const onSelectCentroDeCusto = (centrodecusto) => {
        if (centrodecusto) {
            setCentrodecusto(centrodecusto)
        }
    }

    const updateOrcamentos = (orcamentoAlterado) => {
        let itemsToChange = [...items];
        for (let i = 0; i < itemsToChange.length; i++) {
            if (itemsToChange[i].centrodecusto.id === orcamentoAlterado.centrodecusto.id) {
                itemsToChange[i].valor = orcamentoAlterado.valor;
                break;
            }
        }
        setItems(itemsToChange);
    }

    const addOrRemove = (event) => {
        event.preventDefault();
        event.stopPropagation();
        MonthlyBudgetService.addOrRemove(ano, mes, centrodecusto.id, valor, addBudget).then((result) => {
            if (addBudget) {
                dispatch(setAlert({show: true, message: 'Orçamento aumentado com sucesso', severity: 'success'}));
            } else {
                dispatch(setAlert({show: true, message: 'Orçamento diminuído com sucesso', severity: 'success'}));
            }
            updateOrcamentos(result.data);
            setCentrodecusto(null);
            setValor(null);
        }).catch(() => {
            if (addBudget) {
                dispatch(setAlert({show: true, message: 'Erro ao aumentar orçamento', severity: 'error'}));
            } else {
                dispatch(setAlert({show: true, message: 'Erro ao diminuir orçamento', severity: 'error'}));
            }
        })
    }

    const copy = () => {
        MonthlyBudgetService.copyToNextMonth(ano, mes).then(() => {
            dispatch(setAlert({show: true, message: 'Orçamento copiado com sucesso', severity: 'success'}));
        }, () => {
            dispatch(setAlert({show: true, message: 'Erro ao copiar orçamento', severity: 'error'}));
        })
    }

    const markToAdd = () => {
        setAddBudget(true);
    }

    const markToRemove = () => {
        setAddBudget(false);
    }

    const goToDocuments = async (item) => {
        navigate('/centrodecusto/' + item.centrodecusto.id + '/' + ano + '/' + mes + '/documents');
    }

    const rowOperations = [
        {color: () => "primary", onClick: goToDocuments, label: () => 'Documentos', icon: () => <Payment/>}
    ]

    return items && (
        <form onSubmit={addOrRemove}>
            <CustomRow>
                <SelectOption fullWidth required label="Ano" value={ano}
                              onChange={(event) => setAno(event.target.value)}
                              items={anoOptions} itemValue='name' itemDescription='description'/>
                <SelectOption fullWidth required label="Mês" value={mes}
                              onChange={(event) => setMes(event.target.value)}
                              items={mesOptions} itemValue='name' itemDescription='description'/>
            </CustomRow>
            <CustomRow>
                <ForeignKey fullWidth required label="Tipo de gasto" value={centrodecusto} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCentroDeCusto}
                            onSelect={onSelectCentroDeCusto} findAll={CentroDeCustoService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']}/>
                <CustomTextField type="number" fullWidth required label="Valor (R$)" value={valor || ''}
                                 onChange={(event) => setValor(event.target.value)}/>
                <CustomButtonGroup>
                    <CustomButton color="primary" type="submit" onClick={markToAdd}>Aumentar</CustomButton>
                    <CustomButton color="primary" type="submit" onClick={markToRemove}>Diminuir</CustomButton>
                    <CustomButton type="button" onClick={copy}>Copiar para próximo mês</CustomButton>
                </CustomButtonGroup>
            </CustomRow>
            <Datatable data={items} totalData={items.length} dataColumnNames={['ID', 'Tipo de gasto', 'Valor']}
                       dataColumns={['centrodecusto.id', 'centrodecusto.descricao', 'valor']}
                       dataTypes={['number', 'text', 'currency']} rowOperations={rowOperations}/>
        </form>
    )

}

export default React.memo(MonthlyBudget);